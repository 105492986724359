body {
  margin: 0;
}

.p-dropdown-panel {
  z-index: 10002 !important;
}

button {
  min-height: 40px;
}